import React, {useCallback, useEffect, useMemo, useRef, useState} from "react"
import AssetViewer from "./AssetViewer";
import "./style.css"
import {useSwipeable} from "react-swipeable";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {fetchStarted, sharedMediaShare} from "../../services/sharedMediaSlice";
import {fetchShareMediaPage} from "../../services/api";
import {Media} from "../../services/types";
import {ReactComponent as IconDownload} from "../common/images/icon-download-white.svg"
import debounce from 'lodash.debounce';
import MenuItem from "../common/MenuItem";

interface PositionalAsset {
    prev?: Media,
    current?: Media,
    next?: Media
}

function MediaViewer() {
    const {shareUuid, shareMediaId, assetId} = useParams()
    const shareMedia = useAppSelector(sharedMediaShare);
    const dispatch = useAppDispatch();
    const navigate = useNavigate()
    const [controlsVisible, setControlsVisible] = useState(false)
    const [hiddenEndTime, setHiddenEndTime] = useState(0)
    const hiddenEndTimeRef = useRef(hiddenEndTime)
    hiddenEndTimeRef.current = hiddenEndTime
    const asset = useMemo(() => {
        if (shareMedia.fetchState !== "complete") {
            return null;
        }
        if (!shareMedia || !shareMedia.media) {
            return null;
        }
        const assetIdNum = parseInt(assetId || "")
        for (let i = 0; i < shareMedia.media.length; i++) {
            if (shareMedia.media[i] == null) {
                continue;
            }
            if (assetIdNum === shareMedia.media[i].id) {
                const posAsset: PositionalAsset = {
                    prev: i > 0 ? shareMedia.media[i - 1] : undefined,
                    current: shareMedia.media[i],
                    next: i < (shareMedia.media.length - 1) ? shareMedia.media[i + 1] : undefined
                };
                return posAsset;
            }
        }
        if (shareMedia.media.length === 0) {
            return null;
        }
        //Asset not found
        // const posAsset: PositionalAsset = {
        //     prev: undefined,
        //     current: share.share.sharedMedias[0],
        //     next: (share.share.sharedMedias.length > 1) ? share.share.sharedMedias[1] : undefined
        // };
        // return posAsset;
        navigate(`/pubshare/${shareUuid}`)
    }, [shareMedia, assetId, navigate, shareUuid])
    const assetRef = useRef(asset)
    assetRef.current = asset

    const incrementAsset = useCallback((increment: number) => {
        if (increment === -1) {
            navigate(`/${shareUuid}/${shareMediaId}/${assetRef.current?.prev?.id}`)
        } else if (increment === 1) {
            navigate(`/${shareUuid}/${shareMediaId}/${assetRef.current?.next?.id}`)
        }
    }, [navigate, assetRef, shareUuid, shareMediaId])
    useEffect(() => {
        const onKeyPress = (evt: any) => {
            if (evt.key === "ArrowLeft" || evt.key === "ArrowDown") {
                incrementAsset(-1)
            } else if (evt.key === "ArrowRight" || evt.key === "ArrowUp") {
                incrementAsset(1)
            }
        }
        window.addEventListener("keydown", onKeyPress)
        return () => window.removeEventListener("keydown", onKeyPress)
    }, [incrementAsset])

    useEffect(() => {
        if (shareUuid && shareMediaId && assetId && shareMedia && (shareMedia.fetchState === "init" || shareMedia.shareMediaId !== parseInt(shareMediaId))) {
            fetchStarted({shareMediaId: parseInt(shareMediaId), stat: {videos: 0, photos: 0}})
            fetchShareMediaPage(dispatch, shareUuid, parseInt(shareMediaId), 0, 10, parseInt(assetId))
        }
    }, [shareUuid, shareMediaId, assetId, dispatch, shareMedia])

    const swipeHandlers = useSwipeable({
        onSwipedRight: (() => {
            incrementAsset(-1)
        }),
        onSwipedLeft: (() => {
            incrementAsset(1);
        }),
        onTap: (() => {
            showControls();
        })
    })

    const checkForControlVisibibiltyTimeout = () => {
        let timeSinceMovement = Date.now() - hiddenEndTimeRef.current;
        if (!controlsVisible) {
            return;
        }

        if (timeSinceMovement > 5000) {
            setControlsVisible(false)
        } else {
            setTimeout(checkForControlVisibibiltyTimeout, 1000)
        }
    }

    function showControls() {
        setControlsVisible(true);
        setHiddenEndTime(Date.now());
        setTimeout(checkForControlVisibibiltyTimeout, 5000)
    }

    const onMouseMove = debounce(showControls, 100)

    function downloadUrl(url: string) {
        window.open(`${process.env.REACT_APP_WEB_API_URI}/pub/v1/share/download?assetURL=${encodeURIComponent(url)}`)
    }

    return <div {...swipeHandlers} className="media-viewer" onMouseMove={onMouseMove}>
        <div className={"controls-overlay " + (controlsVisible ? "" : "hidden")}>
            <header>
                <Link to={`/${shareUuid}/${shareMediaId}`}><img className="back-arrow"
                                                                src="/images/icon-backarrow-white.svg"
                                                                style={{width: "50px", maxWidth: "100px"}}
                                                                alt="back"/></Link>
                <div className="title">&nbsp;</div>
                {/*<div className="title">{asset?.current?.title || ""}</div>*/}
                {/*<a onClick={downloadCurrentAsset} style={{color:"white"}}><IconDownload/> Download</a>*/}
                {/*<a onClick={()=>setInfoSliderOpen(true)} style={{color:"white"}}><IconInfo/> Info</a>*/}
                {/*<div className="action-icons">*/}
                <MenuItem onClick={() => downloadUrl(asset?.current?.srcMediaUrl || "")} label={"Download"}
                          icon={<IconDownload/>} style={{color: "white", paddingTop: "10px"}}/>
                {/*<MenuItem onClick={()=>setInfoSliderOpen(true)} label={"Info"} icon={<IconInfo/>} style={{color:"white"}} />*/}
                {/*</div>*/}
            </header>
            <div className="asset-container">
                {asset?.prev &&
                    <Link className={"left-arrow"} to={`/${shareUuid}/${shareMediaId}/${asset.prev.id}`}><img
                        src="/images/icon-image-arrow-left.svg" width="100" alt="&lt;"/></Link>}
                <div className="asset"/>
                {asset?.next &&
                    <Link className={"right-arrow"} to={`/${shareUuid}/${shareMediaId}/${asset.next.id}`}><img
                        src="/images/icon-image-arrow-right.svg" width="100" alt="&gt;"/></Link>}
            </div>
        </div>

        <div className="asset-container">
            <AssetViewer asset={asset?.current}/>
        </div>

    </div>
}

export default MediaViewer;