import React from 'react';
import './App.css';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";

import MediaViewer from "./features/media-view/MediaViewer";
import MediaList from "./features/media-list/MediaList";
import MissingShare from "./features/MissingShare";
import ShareList from "./features/share-list/ShareList";

function App() {
    // useEffect(()=>{
    //     if(window.location.hash){
    //         let hashString = window.location.hash.substring(1)
    //         let parts = hashString.split("&")
    //         let auth = {
    //             access_token:"",
    //             expires_in:0
    //         }
    //         for(let i=0;i<parts.length;i++){
    //             let keyValue = parts[i].split("=")
    //             if(keyValue[0] === "access_token"){
    //                 auth.access_token = keyValue[1]
    //             }
    //             if(keyValue[0] === "expires_in"){
    //                 auth.expires_in = parseInt(keyValue[1])
    //             }
    //         }
    //     }
    // },[window.location.hash])

    return (
        <div className="App" id="App">
            <Router>
                <Routes>
                    <Route path="/pubshare/:shareUuid/:shareMediaId/:assetId" element={<MediaViewer/>}/>
                    <Route path="/pubshare/:shareUuid/:shareMediaId" element={<MediaList/>}/>
                    <Route path="/pubshare/:shareUuid" element={<ShareList/>}/>
                    <Route path="/:shareUuid/:shareMediaId/:assetId" element={<MediaViewer/>}/>
                    <Route path="/:shareUuid/:shareMediaId" element={<MediaList/>}/>
                    <Route path="/:shareUuid" element={<ShareList/>}/>
                    <Route index element={<MissingShare/>}/>
                </Routes>
            </Router>
        </div>
    );
}

export default App;
