import React, {SyntheticEvent} from "react"
import {SharedMedia} from "../../services/types";

type ShareThumbnailProps = {
    shareMedia: SharedMedia,
    selectMode: boolean,
    selected: boolean
}

function onMissingImage(event: SyntheticEvent<HTMLImageElement>) {
    event.currentTarget.src = "/images/icon-missing-asset-grey.svg"
}

function ShareThumbnail({shareMedia, selectMode, selected}: ShareThumbnailProps) {


    return <div className={"shared-media-thumb " + (selectMode ? "select-mode" : "")}>
        {getImageForDisplayMode(selectMode, () => alert("menu"), selected, () => alert("check toggle"), false)}
        <div className="main-thumbnail">
            <img src={shareMedia.thumbnails[0]} alt={shareMedia.title} onError={onMissingImage}
                 className="main-thumbnail"/>
        </div>
        <div className="sub-thumbnail-container">
            <div className="sub-thumbnail">
                <SmallThumb shareMedia={shareMedia} index={1}/>
            </div>
            <div className="sub-thumbnail">
                <SmallThumb shareMedia={shareMedia} index={2}/>
            </div>
            <div className="sub-thumbnail">
                <SmallThumb shareMedia={shareMedia} index={3}/>
            </div>
        </div>
        <div className="thumb-description">
            <div className="album-thumbnail-title">{shareMedia.title || ""}</div>
            <div className="album-item-count-description">
                {shareMedia.stat && shareMedia.stat.photos > 0 && `${shareMedia.stat.photos} Photos `}
                {shareMedia.stat && shareMedia.stat.videos > 0 && `${shareMedia.stat.videos} Videos`}
            </div>
        </div>
    </div>
}

type SmallThumbProps = {
    shareMedia: SharedMedia,
    index: number
}

function SmallThumb({shareMedia, index}: SmallThumbProps) {
    let src = "";
    let alt = "";
    if (index > (shareMedia.thumbnails.length - 1)) {
        src = "/images/icon-missing-asset-grey.svg"
    } else {
        src = shareMedia.thumbnails[index]
        alt = shareMedia.title || ""
    }
    return <img src={src} alt={alt} className={"sub-thumbnail"} onError={onMissingImage}/>
}

const getImageForDisplayMode = (selectMode: boolean, menuToggleHandler: () => void, selected: boolean, selectToggleHandler: () => void, hideMenu: boolean) => {
    if (selectMode) {
        return (
            <div className="album-thumbnail-check">
                <input type="checkbox" checked={selected} onChange={selectToggleHandler}/>
            </div>
        );
    } else if (!hideMenu) {
        return (
            <div className="album-thumbnail-menu-button" onClick={menuToggleHandler}>
                <div className="album-thumbnail-menu-image"/>
            </div>
        );
    }
};
export default ShareThumbnail;