import React, {SyntheticEvent, useCallback, useEffect, useState} from "react"
import {Media} from "../../services/types";

type AssetViewerProps = {
    asset?: Media
}
type Dimension = {
    width: number,
    height: number
}

function AssetViewer({asset}: AssetViewerProps) {
    const [videoDimension, setVideoDimension] = useState<Dimension>(calculateVideoDimensions(16 / 9))
    const onLoadedMetadata = useCallback((event: SyntheticEvent<HTMLVideoElement>) => {
        let videoAspectRatio = event.currentTarget.width / event.currentTarget.height
        setVideoDimension(calculateVideoDimensions(videoAspectRatio))
    }, [setVideoDimension])

    function calculateVideoDimensions(videoAspectRatio: number) {
        let windowAspectRatio = window.innerWidth / window.innerHeight
        if (videoAspectRatio > windowAspectRatio) {
            return {width: window.innerWidth, height: window.innerWidth / videoAspectRatio}
        } else {
            return {width: window.innerHeight * videoAspectRatio, height: window.innerHeight}
        }
    }

    useEffect(() => {
        const onWindowResize = (event: Event) => {
            let videoElement = document.getElementById("asset_video") as HTMLVideoElement
            if (videoElement) {
                let videoAspectRatio = videoElement.videoWidth / videoElement.videoHeight
                // debugger
                setVideoDimension(calculateVideoDimensions(videoAspectRatio))
            }
        }
        window.addEventListener("resize", onWindowResize)
        return () => {
            window.removeEventListener("resize", onWindowResize)
        }
    }, [setVideoDimension])
    return <div className="asset">
        {asset && asset.elementType === "photo" && <img src={asset.srcMediaUrl} alt={asset.title || "untitled"}/>}
        {asset && asset.elementType === "video" && <video id="asset_video" src={asset.srcMediaUrl}
                                                          autoPlay={true}
                                                          controls={true}
                                                          controlsList="nodownload"
                                                          style={{
                                                              width: videoDimension.width,
                                                              height: videoDimension.height
                                                          }}
                                                          onLoadedMetadata={onLoadedMetadata}/>}
    </div>
}

export default AssetViewer;