import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../app/store';
import {AccessToken} from "./types";


export interface AuthState {
    token: AccessToken | null,
    sharePassword?: string,
}

const initialState: AuthState = {
    token: null
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        updateToken: (state, action: PayloadAction<AccessToken>) => {
            if (!action.payload.expires_time) {
                action.payload.expires_time = new Date().getTime() + action.payload.expires_in;
            }
            state.token = action.payload
        },
        clearToken: (state) => {
            state.token = null;
        },
        updateSharePassword: (state, action: PayloadAction<string>) => {
            state.sharePassword = action.payload
        }
    },
});

export const {updateToken, clearToken, updateSharePassword} = authSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectAuth = (state: RootState) => state.auth;
export default authSlice.reducer;
