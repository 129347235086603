import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../app/store';
import {Share} from "./types";


export interface MediaListState {
    share: Share | null,
    fetchState: 'init' | 'loading' | 'complete' | 'failed'
}

const initialState: MediaListState = {
    share: null,
    fetchState: 'init',
};

export const shareSlice = createSlice({
    name: 'mediaList',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        fetchStarted: (state) => {
            state.fetchState = 'loading'
        },
        fetchCompleted: (state, action: PayloadAction<Share>) => {
            state.fetchState = 'complete'
            state.share = action.payload
        },
        fetchFailed: (state) => {
            state.fetchState = 'failed'
        },

    },
});

export const {fetchStarted, fetchCompleted, fetchFailed} = shareSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectShare = (state: RootState) => state.share;
export default shareSlice.reducer;
