import {Action, combineReducers, configureStore, ThunkAction} from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage/session';
import {persistReducer} from 'redux-persist';

import shareReducer from '../services/shareSlice';
import sharedMediaReducer from '../services/sharedMediaSlice'
import authReducer from '../services/authSlice'
import selectedAssetsReducer from '../services/selectedAssetsSlice'

const persistConfig = {
    key: 'root',
    storage,
}
const combinedReducers = combineReducers({
        share: shareReducer,
        sharedMedia: sharedMediaReducer,
        auth: authReducer,
        selectedAssets: selectedAssetsReducer
    }
);
const persistedReducer = persistReducer(persistConfig, combinedReducers)

export const store = configureStore({
    reducer: persistedReducer,
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType,
    RootState,
    unknown,
    Action<string>>;
