import React, {useEffect, useState} from "react"
import Modal from 'react-modal';
import {useAppSelector} from "../../../app/hooks";
import {selectAuth} from "../../../services/authSlice";
import {fetchAlbums} from "../../../services/api";
import {CustomCollection} from "../../../services/types";
import {ReactComponent as CloseIcon} from "../images/icon-select-close.svg"
import {ReactComponent as AddIcon} from "../images/icon-add.svg"
import "./add-to-album-dialog.css"

type AddToAlbumDialogProps = {
    isOpen: boolean,
    closeModal: () => void,
    onChooseAlbum: (id: number) => void
}
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    }
};
Modal.setAppElement("#root")

function AddToAlbumDialog({isOpen, closeModal}: AddToAlbumDialogProps) {
    const [albums, setAlbums] = useState<CustomCollection[]>([])
    const auth = useAppSelector(selectAuth);
    useEffect(() => {
        if (auth && auth.token) {
            fetchAlbums(auth.token.access_token, 0, 50)
                .then(result => {
                    if (result) {
                        setAlbums(result)
                    }
                })
        }
    }, [auth])
    return <Modal isOpen={isOpen} style={customStyles} onRequestClose={closeModal}>
        <div className="add-to-album-dialog">
            <header>
                <h1>Add To Album</h1>
                <CloseIcon style={{width: "40px"}} onClick={closeModal} cursor={"pointer"}/>
            </header>
            <button><AddIcon style={{width: "40px;", height: "40px"}}/> Create new album</button>
            <h3>Pick an album</h3>
            <div className="album-short-listing-container">
                {albums.map(it => {
                    return <div className="album-short-listing" key={it.id}>
                        <img src={it.thumbnails[0]} alt={it.title} className={"album-short-listing-thumb"}/>
                        <div className="album-short-listing-description">
                            <div>{it.title}</div>
                            <div
                                className={"stat-description"}>{it.stats.numPhotos} Photos, {it.stats.numClips} Videos
                            </div>
                        </div>
                    </div>
                })}
            </div>
        </div>
    </Modal>
}

export default AddToAlbumDialog;