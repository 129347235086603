import React, {useCallback, useEffect, useMemo, useState} from "react"
import {fetchStarted, sharedMediaShare} from "../../services/sharedMediaSlice";
import {Link, useNavigate, useParams} from "react-router-dom";
import {fetchShareMediaPage, redeemAuthorizationToken, transferShareMediaAssets} from "../../services/api";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import Thumbnail, {ThumbnailPlaceholder} from "./Thumbnail";
import LoadingSpinner from "../common/LoadingSpinner";
import {selectShare} from "../../services/shareSlice";
import {ReactComponent as SelectIcon} from "../common/images/icon-select-dark.svg";
import {ReactComponent as SelectIconWhite} from "../common/images/icon-select-white.svg";
import {ReactComponent as AddIcon} from "../common/images/my-add-album-white.svg";
import {ReactComponent as DeSelectIcon} from "../common/images/arrow-counterclockwise.svg";
import BackHeader from "../common/BackHeader";
import MenuItem from "../common/MenuItem";
import ActionHeader from "../common/ActionHeader";
import {addAll, clear, selectSelectedAssets, setSelectMode, toggle} from "../../services/selectedAssetsSlice";
import {clearToken, selectAuth, updateToken} from "../../services/authSlice";
import AddToAlbumDialog from "../common/dialogs/AddToAlbumDialog";
import debounce from "lodash.debounce";

function MediaList() {
    const {shareUuid, shareMediaId} = useParams()
    const share = useAppSelector(selectShare);
    const shareMedia = useAppSelector(sharedMediaShare)
    const dispatch = useAppDispatch()
    const {selectedAssets, selectMode} = useAppSelector(selectSelectedAssets)
    const auth = useAppSelector(selectAuth)
    const navigate = useNavigate()
    const queryParams = new URLSearchParams(window.location.search)

    const code = queryParams.get("code")
    const action = queryParams.get("action")
    const [addDialogOpen, setAddDialogOpen] = useState<boolean>(false);
    const [showLoading, setShowLoading] = useState(false);
    useEffect(() => {

        if (shareUuid && shareMediaId && (shareMedia.fetchState === "init" || shareMedia.shareMediaId !== parseInt(shareMediaId))) {
            let shareStat = {videos: 0, photos: 0}
            if (share.share) {
                let thisShare = share.share.sharedMedias.find(it => it.id === parseInt(shareMediaId))
                if (thisShare) {
                    shareStat = thisShare.stat
                }

            }
            dispatch(fetchStarted({shareMediaId: parseInt(shareMediaId), stat: shareStat}))
            fetchShareMediaPage(dispatch, shareUuid, parseInt(shareMediaId))
        }
    }, [shareUuid, shareMediaId, share.share, dispatch, shareMedia.shareMediaId, shareMedia.fetchState])
    useEffect(() => {
        if (code) {
            redeemAuthorizationToken(code)
                .then(atToken => {
                    if (!atToken || atToken.access_token == null) {
                        dispatch(clearToken())
                    } else {
                        dispatch(updateToken(atToken));
                    }
                })

        }
    }, [code, dispatch])

    const toggleSelectMode = useCallback(() => {
        dispatch(setSelectMode(!selectMode))
    }, [dispatch, selectMode])

    const toggleAsset = useCallback((id: number, checked?: boolean) => {
        dispatch(toggle({id, checked}))
    }, [dispatch]);

    const selectAll = useCallback(() => {
        dispatch(addAll(shareMedia.media.map(it => it.id)))
    }, [dispatch, shareMedia]);

    const deselectAll = useCallback(() => {
        dispatch(clear())
    }, [dispatch])


    const transferAssets = useMemo(() => debounce(() => {
        console.log("Transferring assets");
        setShowLoading(true);
        transferShareMediaAssets(shareUuid || "", parseInt(shareMediaId || "0"), selectedAssets)
            .then(result => {
                setShowLoading(false)
                if (result.status === 200) {
                    alert("Transfer complete")
                    deselectAll()
                    toggleSelectMode()
                    navigate(`/${shareUuid}/${shareMediaId}`)
                } else {
                    result.text().then(errorMessage => {
                        console.log(`Transfer failed: ${errorMessage}`);
                        //alert(`Transfer failed - these assets were already transferred to this account and can not be transferred twice`)
                    })
                }
            })
            .catch(error => {
                setShowLoading(false)
                alert("Failed to transfer assets: " + error)
            })
    }, 500), [deselectAll, selectedAssets, shareMediaId, shareUuid, navigate, toggleSelectMode])

    /** Cleanup the debounce handler on unload **/
    useEffect(() => {
        return () => {
            transferAssets.cancel();
        }
    }, [transferAssets])

    const addToAccount = useCallback(() => {
        if (auth.token == null || !auth.token.expires_time || auth.token.expires_time < new Date().getTime()) {
            window.location.href = `${process.env.REACT_APP_CAS_URL}&redirect_uri=${window.location.origin}/pubshare/${shareUuid}/${shareMediaId}?action=addSelected`
        } else {
            transferAssets();
        }
    }, [auth, transferAssets, shareUuid, shareMediaId]);

    useEffect(() => {
        if (action === "addSelected" && auth.token != null && auth.token.expires_time > new Date().getTime()) {
            //setAddDialogOpen(true);
            transferAssets();
        } else if (action === "addSelected") {
            console.log(`addSelected, but not ready. action: ${action} auth.token: ${auth.token} `)
        }
    }, [action, auth.token, transferAssets])
    const shareMediaTitle = useMemo(() => {
        if (share && shareMedia && share.share) {
            let theShareMedia = share.share.sharedMedias.filter(it => it.shareId === shareMedia.shareMediaId)
            if (theShareMedia.length > 0) {
                return theShareMedia[0].title
            }
        }
        return ""
    }, [shareMedia, share])

    function onChooseAlbum(id: number) {
        //TODO: implement this eventually
        console.log(`Request to transfer assets to album ${id}`)
    }

    return <div className="container-fluid ">
        {!selectMode && <BackHeader backLink={`/${shareUuid}`}>
            <MenuItem onClick={toggleSelectMode} label={"Select"} icon={<SelectIconWhite/>}/>
        </BackHeader>}
        {selectMode && <ActionHeader onCancel={toggleSelectMode} title={share?.share?.title || ""}>
            {selectedAssets.length > 0 && <MenuItem onClick={addToAccount} label={"Add to Account"} icon={<AddIcon/>}/>}
            {selectedAssets.length > 0 && <MenuItem onClick={deselectAll} label="Deselect All" icon={<DeSelectIcon/>}/>}
            {selectedAssets.length < shareMedia.media.length &&
                <MenuItem onClick={selectAll} label={"Select All"} icon={<SelectIcon/>}/>}
        </ActionHeader>}
        {showLoading && <LoadingSpinner/>}
        {(shareMedia.fetchState === "init" || shareMedia.fetchState === "loading") && <LoadingSpinner/>}
        <div className="thumbnail_container">
            {shareMediaTitle && <h2>{shareMediaTitle}</h2>}
            {shareMedia.media && shareMedia.media.map((it, index) => {
                if (it == null) {
                    return <ThumbnailPlaceholder key={index} selectMode={selectMode}/>
                } else {
                    if (selectMode) {
                        return <Thumbnail key={index} media={it} selectMode={selectMode}
                                          isSelected={selectedAssets.includes(it.id)}
                                          onSelect={(checked?: boolean) => toggleAsset(it.id, checked)}/>
                    } else {
                        return <Link to={`/${shareUuid}/${shareMediaId}/${it.id}`} key={index}>
                            <Thumbnail media={it} selectMode={selectMode} isSelected={selectedAssets.includes(it.id)}
                                       onSelect={(checked?: boolean) => toggleAsset(it.id, checked)}/>
                        </Link>
                    }
                }
            })}
        </div>
        {addDialogOpen && <AddToAlbumDialog isOpen={addDialogOpen} closeModal={() => setAddDialogOpen(false)}
                                            onChooseAlbum={onChooseAlbum}/>}
    </div>
}

export default MediaList;