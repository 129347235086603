import React from "react"
import {Link} from "react-router-dom";

type BackHeaderProps = {
    backLink: string,
    children: JSX.Element
}

function BackHeader({backLink, children}: BackHeaderProps) {
    return <header className="back-header thumbnail_container">
        <Link to={backLink}><img className="back-arrow" src="/images/icon-backarrow.svg" width="50"
                                 alt="back"/></Link>
        <div className='menu-items'>
            {children}
        </div>
    </header>
}

export default BackHeader;