import React from "react";
import MainHeader from "./common/MainHeader";

function MissingShare() {
    return <div className="container-fluid page-container">
        <MainHeader/>
        <div className="row p-1">
            <h1 className="center-text">Share not found</h1>
            <p>
                We were unable to locate the requested share. Please reach out to the person who sent you the share link
                and ask them to generate a new one.
            </p>
        </div>

    </div>
}

export default MissingShare;