import {CustomCollection, PaginatedFetch, Share, SharedMedia} from "./types";
import {fetchCompleted} from "./sharedMediaSlice";
import {store} from "../app/store"
import {updateSharePassword} from "./authSlice";

export function fetchShare(shareUuid: string) {
    return new Promise<Share>((resolve, reject) => {
        let uri = `${process.env.REACT_APP_WEB_API_URI}/pub/v1/share/${shareUuid}`
        fetch(uri, {headers: {"X-Auth": store.getState().auth.sharePassword || ""}})
            .then((response) => {
                if (response.status === 200) {
                    response.json().then(data => {
                        resolve(data)
                    })
                } else if (response.status === 401) {
                    let password = prompt("This share is protected.  Please provide the password")
                    store.dispatch(updateSharePassword(password || ""))
                    fetchShare(shareUuid).then(data => resolve(data))
                } else {
                    reject("Share not found or is private")
                }
            })
            .catch(err => {
                console.log(`Error fetching share: ${err} `)
                reject(`Error fetching share: ${err}`)
            })

    })
}

export function fetchShareMedia(shareUuid: string, shareMediaId: number, offset: number = 0, max: number = 10, firstAssetId?: number) {
    return new Promise<PaginatedFetch<SharedMedia>>((resolve, reject) => {
            let uri = `${process.env.REACT_APP_WEB_API_URI}/pub/v1/share/${shareUuid}/${shareMediaId}?offset=${offset}&max=${max}`;
            if (firstAssetId) {
                uri += "&firstAssetId=" + firstAssetId;
            }
            fetch(uri, {headers: {"X-Auth": store.getState().auth.sharePassword || ""}})
                .then((response) => {
                    if (response.status === 200) {
                        response.json().then(data => {
                            resolve(data)
                        })
                    } else if (response.status === 401) {
                        let password = prompt("This share is protected.  Please enter the password to continue")
                        store.dispatch(updateSharePassword(password || ""))
                        fetchShareMedia(shareUuid, shareMediaId, offset, max).then(data => resolve(data))

                    } else {
                        reject("Share not found")
                    }
                }).catch(err => {
                console.log(`Error fetching share: ${err}`)
                reject("Error fetching share")
            })
        }
    );
}

export function fetchShareMediaPage(dispatch: any, shareUuid: string, shareMediaId: number, offset: number = 0, max: number = 10, firstAssetId?: number) {
    fetchShareMedia(shareUuid, shareMediaId, offset, max, firstAssetId)
        .then(data => {
            dispatch(fetchCompleted({...data, shareMediaId: shareMediaId}))
            if (data.total > (data.offset + data.data.length - 1) && data.data.length > 0) {
                setTimeout(() =>
                    fetchShareMediaPage(dispatch, shareUuid, shareMediaId,
                        data.offset + data.data.length,
                        Math.min(20, data.total - (data.offset + data.data.length)))
                )
            }

        })
        .catch(err => console.log("Error: " + err.message))
}

export function fetchAlbums(token: string, offset: number = 0, max: number = 25) {
    return new Promise<CustomCollection[]>((resolve, reject) => {
        fetch(`${process.env.REACT_APP_WEB_API_URI}/sec/v1/ccs?format=deep&thumb=60x60&offset=${offset}&max=${max}`,
            {
                headers: {"Authorization": `Bearer ${token}`}
            })
            .then(result => {
                if (result.ok) {
                    result.json().then(data => {
                        resolve(data.results)
                    })
                } else {
                    console.log(`Error fetching albums: status: ${result.status}`)
                    if (result.status === 401 || result.status === 403) {
                        //Unauthorized
                        reject("Unauthorized");
                    } else {
                        reject(result);
                    }
                }
            })
            .catch(err => {
                console.log(`Error fetching albums: ${err}`);
                reject(err);
            })
    })
}

export async function redeemAuthorizationToken(ocToken: string) {
    const response = await fetch(`${process.env.REACT_APP_WEB_API_URI}/pub/v1/cas/redeem/token`, {
        method: "POST",
        body: JSON.stringify({
            clientId: process.env.REACT_APP_CAS_CLIENT_ID,
            token: ocToken,
            redirectUri: `${window.location.origin}/`
        })
    })
    return response.json();
}

export async function refreshAuthorizationToken(refreshToken: string) {
    const response = await fetch(`${process.env.REACT_APP_WEB_API_URI}/pub/v1/cas/refresh/token`, {
        method: "POST",
        body: JSON.stringify({
            clientId: process.env.REACT_APP_CAS_CLIENT_ID,
            token: refreshToken
        })
    })
    return response.json();
}

export async function createCustomCollection(title: string) {
    if (!store.getState().auth.token) {
        alert("Login expired - unable to create album");
        return
    }
    const response = await fetch(`${process.env.REACT_APP_WEB_API_URI}/sec/v1/ccs`, {
        method: "POST",
        body: JSON.stringify({
            title: title
        }),
        headers: {"Authorization": `Bearer ${store.getState().auth?.token?.access_token}`}

    })
    return response.json();
}

export async function addAssetsToCustomCollection(customCollectionId: number, ids: number[], assetType: string) {
    if (assetType !== "cce" && assetType !== "cc") {
        alert("Only album elements can be added to accounts at this time.  These assets come from a DVD or PhotoCD")
        return;
    }
    if (!store.getState().auth.token) {
        alert("Login expired - unable to create album");
        return
    }
    if (assetType === "cce") {
        const response = await fetch(`${process.env.REACT_APP_WEB_API_URI}/sec/v1/ccs/${customCollectionId}/add/ccs`, {
            method: "PUT",
            body: JSON.stringify(ids),
            headers: {"Authorization": `Bearer ${store.getState().auth?.token?.access_token}`}
        })
        return response.json();
    } else if (assetType === "cc") {

    }
}

export function transferShare(shareUuid: string) {
    if (!store.getState().auth.token) {
        throw new Error("Login expired - unable to create album")
    }
    return fetch(`${process.env.REACT_APP_WEB_API_URI}/sec/v1/transfer/${shareUuid}`, {
        method: "PUT",
        headers: {"Authorization": `Bearer ${store.getState().auth?.token?.access_token}`}
    })
}

export function transferShareMedia(shareUuid: string, shareMediaId: number) {
    if (!store.getState().auth.token) {
        throw new Error("Login expired - unable to create album")
    }
    return fetch(`${process.env.REACT_APP_WEB_API_URI}/sec/v1/transfer/${shareUuid}/${shareMediaId}`, {
        method: "PUT",
        headers: {"Authorization": `Bearer ${store.getState().auth?.token?.access_token}`}
    })
}

export function transferShareMediaAssets(shareUuid: string, shareMediaId: number, assetIds: number[]) {
    if (!store.getState().auth.token) {
        throw new Error("Login expired - unable to create album")
    }
    return fetch(`${process.env.REACT_APP_WEB_API_URI}/sec/v1/transfer/${shareUuid}/${shareMediaId}/assets`, {
        method: "PUT",
        body: JSON.stringify(assetIds),
        headers: {"Authorization": `Bearer ${store.getState().auth?.token?.access_token}`}
    })
}