import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../app/store';


export interface ToggleSelectedAssetPayload {
    id: number,
    checked?: boolean
}

export interface SelectedAssetState {
    selectMode: boolean,
    selectedAssets: number[]
}

const initialState: SelectedAssetState = {
    selectedAssets: [],
    selectMode: false
};

export const selectedAssetsSlice = createSlice({
    name: 'selectedAssets',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        clear: (state) => {
            state.selectedAssets = []
        },
        add: (state, action: PayloadAction<number>) => {
            state.selectedAssets = [...state.selectedAssets, action.payload]
        },
        addAll: (state, action: PayloadAction<number[]>) => {
            let tmp = [...state.selectedAssets]
            for (let i = 0; i < action.payload.length; i++) {
                tmp.push(action.payload[i])
            }
            state.selectedAssets = tmp;
        },
        remove: (state, action: PayloadAction<number>) => {
            state.selectedAssets = state.selectedAssets.filter(it => it !== action.payload)
        },
        toggle: (state, action: PayloadAction<ToggleSelectedAssetPayload>) => {
            let present = state.selectedAssets.filter(it => it === action.payload.id).length > 0
            if (action.payload.checked === true) {
                if (!present) {
                    state.selectedAssets = [...state.selectedAssets, action.payload.id]
                }
            } else if (action.payload.checked === false) {
                state.selectedAssets = state.selectedAssets.filter(it => it !== action.payload.id)
            } else {
                if (present) {
                    state.selectedAssets = state.selectedAssets.filter(it => it !== action.payload.id)
                } else {
                    state.selectedAssets = [...state.selectedAssets, action.payload.id]
                }
            }
        },
        setSelectMode: (state, action: PayloadAction<boolean>) => {
            state.selectMode = action.payload
        }

    },
});

export const {clear, add, addAll, remove, toggle, setSelectMode} = selectedAssetsSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectSelectedAssets = (state: RootState) => state.selectedAssets;
export default selectedAssetsSlice.reducer;
