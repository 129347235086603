import React, {SyntheticEvent, useCallback, useEffect, useRef} from "react"
import {Media} from "../../services/types";
import {ReactComponent as VideoPlayIcon} from "./images/icon-asset-video-indicator.svg"
import {formatDurationAsTime} from "../common/format";

interface ThumbnailProps {
    media: Media,
    selectMode: boolean,
    isSelected: boolean,
    onSelect: (checked?: boolean) => void
}

export function Thumbnail({media, selectMode, isSelected, onSelect}: ThumbnailProps) {
    const videoElem = useRef<HTMLVideoElement>(null);

    function onImageError(evt: any) {
        evt.target.src = "/images/icon-missing-asset-grey.svg"
    }

    function isMobile() {
        return (window.screen.width < 500 ||
            window.outerWidth < 500 ||
            navigator.userAgent.match(/Android/i) ||
            navigator.userAgent.match(/webOS/i) ||
            navigator.userAgent.match(/iPhone/i) ||
            navigator.userAgent.match(/iPod/i));
    }

    const playVideo = useCallback((event: SyntheticEvent<HTMLVideoElement>) => {
        if (videoElem == null || videoElem.current == null) {
            return;
        }
        if (isMobile() && !selectMode) {
            event.stopPropagation()
            event.preventDefault()
            //There is no isPlaying attribute.  This is the closest
            if ((videoElem.current.currentTime > 0 && !videoElem.current.paused && !videoElem.current.ended && videoElem.current.readyState > 2)) {
                videoElem.current.pause();

            } else {
                //videoElem.current.controls = true
                videoElem.current.play();
                try {
                    videoElem.current.requestFullscreen()
                        .catch(err => console.log(err))
                } catch (err) {
                    console.log(err);
                }
            }
        }
    }, [videoElem, selectMode])

    useEffect(() => {
        //TODO: This event never seems to fire
        const handleFullScreenChange = (evt: any) => {
            console.log("handleFullScreenChange");
            if (document.fullscreenElement) {
                console.log("handleFullScreenChange::going Full Screen");
                //ignore - going full screen
            } else {
                console.log("handleFullScreenChange::removing Full Screen");
                if (videoElem.current) {
                    videoElem.current.pause();
                }
            }
        }
        // if(document.onfullscreenchange) {
        //     document.addEventListener("webkitfullscreenchange mozfullscreenchange fullscreenchange", handleFullScreenChange)
        // }
        // return ()=> { document.removeEventListener("webkitfullscreenchange mozfullscreenchange fullscreenchange", handleFullScreenChange)}
        if (videoElem.current) {
            console.log("Adding fullscreenchange listener")
            videoElem.current.addEventListener("fullscreenchange", handleFullScreenChange);
        }
        // return () => {
        //     console.log("Removing fullscreenchange listener");
        //     if(videoElem.current) {
        //         videoElem.current.removeEventListener("fullscreenchange", handleFullScreenChange);
        //     }
        // }
    }, [videoElem])

    return <div className="thumbnail" title={media.title} onClick={selectMode ? () => onSelect() : undefined}>
        {selectMode && <input type="checkbox" checked={isSelected} className="thumb-select" onChange={(e) => {
            onSelect(e.target.checked);
        }}/>}
        {(media.elementType === "photo" || !isMobile()) &&
            <img src={media.thumbnail} alt={media.title} className="media-thumbnail-image" onError={onImageError}/>}
        {media.elementType === "video" && isMobile() &&
            <video poster={media.thumbnail}
                   ref={videoElem}
                   src={media.srcMediaUrl}
                   className={"media-thumbnail-image"}
                   style={{height: "100px", width: "100px"}}
                   controlsList="nodownload"
                   onClick={(event) => playVideo(event)}
                   controls={false}
            />
        }
        <div className="thumb-info">
            {media.elementType === "video" && <VideoPlayIcon width={18} height={18} className={"thumbnail-play-icon"}/>}
            {media.elementType === "video" && <span>{formatDurationAsTime(media?.duration || 0)}</span>}
            {/*<span title={media.title}>{media.title}</span>*/}
        </div>
    </div>
}

interface ThumbnailPlaceholderProps {
    selectMode: boolean
}

export function ThumbnailPlaceholder({selectMode}: ThumbnailPlaceholderProps) {
    return <div className="thumbnail" title="Loading ...">
        {selectMode && <input type="checkbox" checked={false} className="thumb-select"/>}
        {/*<img src={media.thumbnail} alt={media.title}/>*/}
        <div className="thumb-info">
            <span title="Loading...">Loading...</span>
        </div>
    </div>
}

export default Thumbnail