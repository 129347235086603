import React from "react";
import MenuItem from "./MenuItem";
import "./style.css"

type ActionHeaderProps = {
    onCancel: () => void,
    title: string,
    children?:
        | React.ReactNode
        | React.ReactNode[]
        | boolean;
}

function ActionHeader({onCancel, title, children}: ActionHeaderProps) {
    return <header className="action-header">
        <div className="action-header-inner">

            <MenuItem onClick={onCancel} label={"Cancel"} icon={<img src={require("./images/icon-select-close.png")}
                                                                     style={{width: "24px", height: "24px"}}
                                                                     alt="Cancel"/>}/>
            <div className="title">{title}</div>
            {/*<div className='menu-items'>*/}
            {children}
            {/*</div>*/}
        </div>
    </header>
}

export default ActionHeader;