import React, {CSSProperties, ReactComponentElement} from "react"

type MenuItemProps = {
    onClick: () => void,
    icon?: ReactComponentElement<any>,
    label: string,
    style?: CSSProperties
}

function MenuItem({onClick, icon, label, style}: MenuItemProps) {
    return <a href="#!" className="menu-button" title={label} onClick={onClick} style={style}>
        {icon} <span>{label}</span>
    </a>
}

export default MenuItem;