import React, {useEffect} from "react"
import {Link, useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {fetchCompleted, fetchStarted, selectShare} from "../../services/shareSlice";
import MainHeader from "../common/MainHeader";
import LoadingSpinner from "../common/LoadingSpinner";
import ShareThumbnail from "./ShareThumbnail";
import {fetchShare} from "../../services/api";

function ShareList() {
    const {shareUuid} = useParams();
    const share = useAppSelector(selectShare)
    const dispatch = useAppDispatch()
    useEffect(() => {
        if (shareUuid) {
            dispatch(fetchStarted)
            fetchShare(shareUuid || "")
                .then(it => dispatch(fetchCompleted(it)))
        }
    }, [shareUuid,dispatch])
    return <div className="container-fluid page-container share-list">
        <MainHeader/>
        {share && share.fetchState === "loading" && <LoadingSpinner/>}
        {share && share.share && share.fetchState === "complete" &&
            <div className="share-thumb-container">
                <h1>Albums</h1>
                {share.share.sharedMedias.map((it) =>
                    <Link to={`/${shareUuid}/${it.id}`} key={it.id}>
                        <ShareThumbnail
                            key={it.id}
                            shareMedia={it}
                            selectMode={false}
                            selected={false}
                        />
                    </Link>
                )}
            </div>
        }
    </div>
}

export default ShareList;