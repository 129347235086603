export const formatAsPhone = (val: string) => {
    if (val === null) {
        return val;
    }
    let theDigits = val.replace(/\D/g, '');
    if (theDigits.length <= 3) {
        return val;
    }

    let result = "("
    if (theDigits.length >= 3) {
        result += theDigits.substring(0, 3);
    }
    if (theDigits.length === 3) {
        return val;
    }
    result += ")"
    result += " " + theDigits.substring(3, Math.min(theDigits.length, 6))

    if (theDigits.length >= 7) {
        result += "-" + theDigits.substring(6, Math.min(theDigits.length, 10))
    }
    return result;
}

export const formatUppercase = (val: string) => {
    if (val === null) return val;
    return val.toUpperCase();
}
export const formatAsCreditCard = (val: string) => {
    return val;
}

export const formatDurationAsTime = (durationSeconds: number, showHours = false, zeroPadParts = false) => {
    var minutes = Math.floor(Math.floor(durationSeconds) / 60);
    durationSeconds = Math.ceil(Math.floor(durationSeconds) % 60);
    var hours = Math.floor(minutes / 60)
    if (showHours) {
        minutes = minutes % 60;
    }
    let output = "";
    if (showHours) {
        if (zeroPadParts) {
            output = padTwoDigitNumber(hours) + ":" + padTwoDigitNumber(minutes) + ":" + padTwoDigitNumber(durationSeconds);
        } else {
            if (hours > 0) {
                output = hours + ":" + padTwoDigitNumber(minutes) + ":" + padTwoDigitNumber(durationSeconds);
            } else {
                output = minutes + ":" + padTwoDigitNumber(durationSeconds);
            }
        }
    } else {
        if (zeroPadParts) {
            output = padTwoDigitNumber(hours) + ":" + padTwoDigitNumber(minutes) + ":" + padTwoDigitNumber(durationSeconds);
        } else {
            output = minutes + ":" + padTwoDigitNumber(durationSeconds);
        }
    }
    return output;
}
export const formatDurationAsWords = (numSeconds?: number) => {
    if (numSeconds == null) {
        return "0 minutes "
    }
    let result = ""
    if (numSeconds > 60 * 60) {
        result = Math.floor(numSeconds / (60 * 60)) + " hours "
    }
    if (numSeconds > 60) {
        let numMin = Math.floor((numSeconds % (60 * 60)) / 60);
        if (numMin > 0) {
            result += Math.floor((numSeconds % (60 * 60)) / 60) + " minutes "
        }
    } else {
        result += numSeconds + " seconds"
    }
    return result;
}
export const padTwoDigitNumber = (num: number) => {
    return ("0" + num).slice(-2);
}