import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../app/store';
import {Media, MediaListFetch} from "./types";


export interface SharedMediaListState {
    count: number,
    offset: number,
    shareMediaId: number,
    media: Media[],
    fetchState: 'init' | 'loading' | 'partial' | 'complete' | 'failed'
}

const initialState: SharedMediaListState = {
    count: 0,
    offset: 0,
    shareMediaId: 0,
    media: [],
    fetchState: 'init',
};

export const sharedMediaSlice = createSlice({
    name: 'sharedMediaList',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        fetchStarted: (state, action: PayloadAction<MediaListFetch>) => {
            state.fetchState = 'loading'
            state.count = 0
            state.shareMediaId = action.payload.shareMediaId
            state.media = []
            if (action) {
                state.media = new Array(action.payload.stat.videos + action.payload.stat.photos)
            }

        },

        fetchCompleted: (state, action) => {
            state.count = action.payload.total
            state.offset = action.payload.offset
            state.shareMediaId = action.payload.shareMediaId
            if (state.fetchState === "init" || state.fetchState === "loading") {
                //First fetch
                let sharedMedias = new Array(action.payload.total - action.payload.data.length).fill(null);
                for (let i = action.payload.offset; i < action.payload.offset + action.payload.data.length; i++) {
                    sharedMedias[i] = action.payload.data[i - action.payload.offset]
                }
                state.media = sharedMedias

            } else {
                for (let i = action.payload.offset; i < action.payload.offset + action.payload.data.length; i++) {
                    state.media[i] = action.payload.data[i - action.payload.offset]
                }
            }
            state.fetchState = 'complete'
        },
        fetchFailed: (state) => {
            state.fetchState = 'failed'
        },

    },
});

export const {fetchStarted, fetchCompleted, fetchFailed} = sharedMediaSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const sharedMediaShare = (state: RootState) => state.sharedMedia;
export default sharedMediaSlice.reducer;
